import React from "react";
import classnames from "classnames";

import { Section } from "../../common";

import style from "./Partners.module.scss";

import logoRp from "../../../assets/images/logo-rp.svg";
import logoFe from "../../../assets/images/logo-fe.svg";
import logoUe from "../../../assets/images/logo-ue2.svg";

function Partners(): JSX.Element {
  return (
    <Section sectionClassName={style.section}>
      <div className={classnames(style.imgWrapper, "col-12 col-md-4")}>
        <img src={logoRp} alt="Rzeczpospolita Polska" />
      </div>
      <div className={classnames(style.imgWrapper, "col-12 col-md-4 mt-3 mt-md-0")}>
        <img src={logoFe} alt="Fundusze Europejskie" />
      </div>
      <div className={classnames(style.imgWrapper, "col-12 col-md-4 mt-3 mt-md-0")}>
        <img src={logoUe} alt="Unia Europejska" />
      </div>
    </Section>
  );
}

export default Partners;
