import React from "react";

import { Layout, SEO } from "../components/common";
import { Hero, Partners, SmallDescription, LargeDescription } from "../components/IndexPageSections";

function IndexPage(): JSX.Element {
  return (
    <Layout>
      <SEO title="Home" />
      <Hero />
      <Partners />
      <SmallDescription />
      <LargeDescription />
    </Layout>
  );
}

export default IndexPage;
