import React from "react";

import { Section } from "../../common";

import logo from "../../../assets/images/logo.svg";

import style from "./SmallDescription.module.scss";

function SmallDescription(): JSX.Element {
  return (
    <Section sectionClassName={style.section} rowClassName="justify-content-center">
      <div className="col-12 col-md-8">
        <div className={style.logoWrapper}>
          <img src={logo} alt="Stream AI" />
        </div>
        <p>
          <b>Dostarczamy dynamiczne rekomendacje zakupowe </b> - personalizowane pod danego gracza/odbiorcę lub też
          dynamicznie generowane rekomendacje ogólne, kiedy nie posiadamy informacji na temat danego gracza/odbiorcy.
        </p>
      </div>
    </Section>
  );
}

export default SmallDescription;
