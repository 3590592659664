import React from "react";

import { Section } from "../../common";

import style from "./LargeDescription.module.scss";

function LargeDescription(): JSX.Element {
  return (
    <Section sectionClassName={style.section}>
      <div className="col-12 col-md-6">
        <p>
          StreamAI Spółka z ograniczoną odpowiedzialnością z siedzibą w Gliwicach realizuje projekt dofinansowany z
          Funduszy Europejskich z Programu Operacyjnego Inteligentny Rozwój 2014-2020, Działanie 1.2. Sektorowe programy
          B+R, współfinansowanego ze środków Europejskiego Funduszu Rozwoju Regionalnego
          <br /> <br />
          <b>
            pt. „Stream AI - Opracowanie technologii rekomendacyjnego systemu zakupowego dla graczy kolekcjonerskich
            gier karcianych”
          </b>
        </p>
        <p>
          Termin realizacji: 01.01.2021 – 31.12.2022 <br />
          Wartość projektu: 7 148 138,01 zł <br />
          Kwota dofinansowania: 5 431 039,15 zł
        </p>
      </div>
      <div className="col-12 col-md-6">
        <p>
          <b>Cel projektu:</b> Celem projektu opracowanie technologii rekomendacyjnego systemu zakupowego dla graczy
          kolekcjonerskich gier karcianych (CCG) i stworzenie dedykowanego API oraz komponentu w postaci interaktywnej
          warstwy wyświetlanej na wideo w serwisach strumieniujących rozgrywki w tych grach, jak na przykład Twitch.
        </p>
        <p>
          <b>Planowane efekty:</b>
          - dostarczanie dynamicznie generowanych rekomendacji zakupowych, personalizowanych pod danego gracza/odbiorcę,
          <br />
          - dostarczenie dynamicznie generowanych rekomendacji ogólnych, kiedy nie pozyskano wystarczającej informacji
          na temat danego gracza/odbiorcy,
          <br />- stworzenie interaktywnego komponentu wizualnego, działającego na zasadzie warstwy/nakładki wizualnej
          na strumieniowanie wideo, który będzie mógł być w bardzo prosty sposób użyty podczas strumieniowania gier i
          turniejów CCG.{" "}
        </p>
      </div>
    </Section>
  );
}

export default LargeDescription;
