import React from "react";

import { Section } from "../../common";

import style from "./Hero.module.scss";

function Hero(): JSX.Element {
  return (
    <Section sectionClassName={style.section}>
      <div className="col-12 col-md-6">
        <h1>
          StreamAI to <b>rekomendacyjny system zakupowy</b> dla graczy kolekcjonerskich gier karcianych.
        </h1>
      </div>
    </Section>
  );
}

export default Hero;
